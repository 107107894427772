export default () => {

    let map;
    let locations;

    async function fetchLocations(dealerType) {
        const res = await fetch('https://hyundai.hu/wp-json/wp/v2/dealers/?per_page=100&order=asc&orderby=title&dealer-type=' + dealerType);
        const json = await res.json();
        console.log('asd: '+json)


        let data = [];
        json.forEach(function (item) {
            let website;
            let uniqueUrl;
            if (item.acf.data_elements
                .filter(item => item.acf_fc_layout === 'website')
                .shift()) {

                website = item.acf.data_elements
                    .filter(item => item.acf_fc_layout === 'website')
                    .shift().website_link;
            }

            if (item.acf.is_unique_link) {
                uniqueUrl = item.acf.unique_url;
            }

            data.push({
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(item.acf.longitude),
                        parseFloat(item.acf.latitude),
                    ]
                },
                type: "Feature",
                properties: {
                    category: "car dealership",
                    hours: item.acf.data_elements
                        .filter(item => item.acf_fc_layout === 'opening_hours')
                        .shift()
                        .text,
                    description: "",
                    name: item.title.rendered,
                    address: item.acf.address,
                    storeid: item.id,
                    website: website,
                    uniqueUrl: uniqueUrl,
                    link: item.link,
                    // phone: item.acf.data_elements
                    //     .filter(item => item.acf_fc_layout === 'phone')
                    //     .shift()
                    //     .phone_number,
                    // email: item.acf.data_elements
                    //     .filter(item => item.acf_fc_layout === 'email')
                    //     .shift()
                    //     .email_address,
                    coordinates: {lat: parseFloat(item.acf.latitude), lng: parseFloat(item.acf.longitude)},
                }
            });
        });

        return {
            type: "FeatureCollection",
            features: data
        }
    }

    async function calculateDistances(data, origin) {
        const stores = [];
        const destinations = [];

        // Build parallel arrays for the store IDs and destinations
        data.forEach((store) => {
            const storeNum = store.getProperty('storeid');
            const storeLoc = store.getGeometry().get();

            stores.push(storeNum);
            destinations.push(storeLoc);
        });

        // Retrieve the distances of each store from the origin
        // The returned list will be in the same order as the destinations list
        const service = new google.maps.DistanceMatrixService();
        const getDistanceMatrix =
            (service, parameters) => new Promise((resolve, reject) => {
                service.getDistanceMatrix(parameters, (response, status) => {
                    if (status != google.maps.DistanceMatrixStatus.OK) {
                        reject(response);
                    } else {
                        const distances = [];
                        const results = response.rows[0].elements;
                        for (let j = 0; j < results.length; j++) {
                            const element = results[j];
                            const distanceText = element.distance.text;
                            const distanceVal = element.distance.value;
                            const distanceObject = {
                                storeid: stores[j],
                                distanceText: distanceText,
                                distanceVal: distanceVal,
                            };
                            distances.push(distanceObject);
                        }

                        resolve(distances);
                    }
                });
            });

        const distancesList = await getDistanceMatrix(service, {
            origins: [origin],
            destinations: destinations,
            travelMode: 'DRIVING',
            unitSystem: google.maps.UnitSystem.METRIC,
        });

        distancesList.sort((first, second) => {
            return first.distanceVal - second.distanceVal;
        });

        return distancesList;
    }


    function appendLink(title_text, text, location, container) {
        const title_element = document.createElement('h5');
        title_element.innerText = title_text;
        container.appendChild(title_element);

        const link = document.createElement('a');
        link.innerText = text;
        link.href = location;
        container.appendChild(link);
    }

    function appendParagraph(title_text, text, container) {
        const title_element = document.createElement('h5');
        title_element.innerText = title_text;
        container.appendChild(title_element);

        const text_element = document.createElement('p');
        text_element.innerText = text;
        container.appendChild(text_element);
    }

    function prependBackLink(container) {
        const link = document.createElement('span');
        link.classList.add('details-toggle');

        link.innerText = 'Vissza';

        link.addEventListener('click', () => {
            showStoresList(map.data);
            map.setZoom(7);
            map.panTo({lat: 47.2, lng: 19.0833})
        });

        container.prepend(link);
    }

    function appendDetailsLink(container, feature) {
        const link = document.createElement('span');
        link.innerText = 'Részletek';
        link.feature = feature;
        link.classList.add('details-toggle');

        link.addEventListener('click', function (event) {
            console.log(event.target.feature)

            showDetails(event.target.feature);
        });

        container.appendChild(link);
    }

    function appendTitle(title_text, container) {
        const title = document.createElement('h4');
        title.innerText = title_text;
        container.appendChild(title);
    }

    function getLocationObj(feature) {

        return {
            storeid: feature.getProperty('storeid'),
            name: feature.getProperty('name'),
            phone: feature.getProperty('phone'),
            email: feature.getProperty('email'),
            address: feature.getProperty('address'),
            link: feature.getProperty('url'),
            hours: feature.getProperty('hours'),
            website: feature.getProperty('website'),
            coordinates: feature.getProperty('coordinates'),
            dealerLink: feature.getProperty('link'),
            uniqueLink: feature.getProperty('uniqueUrl'),
        };
    }

    function showDetails(feature) {
        const location = getLocationObj(feature);

        let parent = document.getElementById('locations');
        parent.scroll(0, 0)
        const container_div = document.createElement('div');
        container_div.classList.add('location-detail');
        setTimeout(() => {
            container_div.classList.add('show');
        }, 100);

        container_div.store = location.storeid;

        prependBackLink(container_div);

        appendTitle(location.name, container_div);
        if (location.website) {
            appendLink('Weboldal:', location.website, location.website, container_div);
        }
        if (location.phone) {
            appendLink('Telefon:', location.phone, "tel:" + location.phone, container_div);
        }

        if (location.email) {
            appendLink('Email:', location.email, "mailto:" + location.email, container_div);
        }
        appendParagraph('Cím:', location.address, container_div);
        appendParagraph('Nyitvatartás:', location.hours, container_div);


        parent.childNodes.forEach((child) => {
            if (!child.store && child.store !== location.storeid) {
                child.classList.add('hide');
                child.classList.remove('show');
            } else {
                child.parentNode.removeChild(child);
            }
        });

        if (parent.children.length === 0) {
            parent.appendChild(container_div);
        } else if ([...parent.children].filter((child) => child.store === location.storeid).length === 0) {
            parent.appendChild(container_div);
        }
        map.panTo(location.coordinates);
        map.setZoom(10);

        console.log(feature)

        container_div.insertAdjacentHTML('beforeend', ' <a ' + (location.uniqueLink ? 'target="_blank"' : '' ) + ' href="' + (location.uniqueLink ? location.uniqueLink : location.dealerLink) + '#">\n' +
            '                                                        <div class="cta-button">\n' +
            '                                                            <span class="cta-arrow is-right"></span>\n' +
            '                                                            <span class="cta-text">Kereskedés felkeresése</span>\n' +
            '                                                        </div>\n' +
            '                                                    </a>')
    }

    function showStoresListWithDistance(data, stores) {
        let parent = document.getElementById('locations');

        parent.replaceChildren();

        stores.forEach((store) => {
            const container_div = document.createElement('div');
            container_div.classList.add('location')

            const currentStore = data.getFeatureById(store.storeid);

            appendTitle(currentStore.getProperty('name'), container_div);
            appendParagraph('Cím:', currentStore.getProperty('address'), container_div);
            appendParagraph('Távolság:', store.distanceText, container_div);
            appendDetailsLink(container_div, currentStore);

            parent.appendChild(container_div);
        });
    }

    function showStoresList(data) {
        let parent = document.getElementById('locations');

        parent.replaceChildren();

        locations.features.forEach((store) => {
            const container_div = document.createElement('div');
            container_div.classList.add('location')
            let currentStore = data.getFeatureById(store.properties.storeid);

            appendTitle(currentStore.getProperty('name'), container_div);
            appendParagraph('Cím:', currentStore.getProperty('address'), container_div);
            appendDetailsLink(container_div, currentStore);

            parent.appendChild(container_div);
        });
    }

    function spinner_off() {
        let blur_container = document.getElementById("blur-container");

        blur_container.classList.add('hidden');
    }


    window.initMap = async function () {

        const mapElement = document.getElementById("dealer-search-map");
        let dealerType = '';

        map = new google.maps.Map(mapElement, {
            center: {lat: 47.2, lng: 19.0833},
            zoom: 7,
            gestureHandling: "cooperative",
        });

        if (mapElement.classList.contains('service-only')) {
            dealerType = 7;
        } else {
            dealerType = 6;
        }

        locations = await fetchLocations(dealerType);


        map.data.addGeoJson(locations, {idPropertyName: 'storeid'})
        showStoresList(map.data);

        spinner_off();

        map.data.addListener('click', (event) => {
            showDetails(event.feature);
        });

        const input = document.getElementById('pac-input');
        const options = {
            types: ['(regions)'],
            componentRestrictions: {country: 'hu'}
        };

        const autocomplete = new google.maps.places.Autocomplete(input, options);

        autocomplete.setFields(
            ['address_components', 'geometry', 'name']);

        // Set the origin point when the user selects an address
        const originMarker = new google.maps.Marker({map: map});
        originMarker.setVisible(false);
        let originLocation = map.getCenter();

        autocomplete.addListener('place_changed', async () => {
            originMarker.setVisible(false);
            originLocation = map.getCenter();
            const place = autocomplete.getPlace();

            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                // window.alert('No address available for input: \'' + place.name + '\'');
                return;
            }

            // Recenter the map to the selected address
            originLocation = place.geometry.location;
            map.setCenter(originLocation);
            map.setZoom(9);

            originMarker.setPosition(originLocation);
            originMarker.setVisible(false);

            // Use the selected address as the origin to calculate distances
            // to each of the store locations
            const rankedStores = await calculateDistances(map.data, originLocation);
            showStoresListWithDistance(map.data, rankedStores);
        });
    }

    function startup() {

        if (!document.getElementById('dealer-search-map')) {
            return;
        }

        console.log('start')

        let script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWFPIM_qAarOxLe4raYWf1UInQDO2gODw&libraries=places&callback=initMap';
        script.async = true;

        document.head.appendChild(script);
    }

    startup();
}
